import React from "react";
import { BaseRecord, IResourceComponentsProps } from "@refinedev/core";
import {
  DateField,
  DeleteButton,
  EditButton,
  EmailField,
  List,
  ShowButton,
  TagField,
  useTable,
} from "@refinedev/antd";
import { Button, message, Space, Table, Tooltip, Typography } from "antd";
import { CopyOutlined } from "@ant-design/icons";

export const AffiliateList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex={["email"]}
          title="Email"
          render={(value: any) => <EmailField value={value} />}
        />
        <Table.Column
          dataIndex={["trackingLink"]}
          title="Tracking Link"
          render={(value: any) => (
            <Space>
              <Typography.Text ellipsis style={{ maxWidth: 200 }}>
                {value}
              </Typography.Text>
              <Tooltip title="Copy to clipboard">
                <Button
                  icon={<CopyOutlined />}
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(value);
                    message.success("Tracking link copied!");
                  }}
                />
              </Tooltip>
            </Space>
          )}
        />
        <Table.Column
          dataIndex={["users"]}
          title="User Count"
          render={(value: any) => <TagField value={value?.length || 0} />}
        />

        <Table.Column
          dataIndex={["createdAt"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={["updatedAt"]}
          title="Updated At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
