import React, { useEffect, useState } from "react";
import {
  BaseRecord,
  CrudFilter,
  CrudFilters,
  IResourceComponentsProps,
  LogicalFilter,
  useApiUrl,
} from "@refinedev/core";
import {
  DateField,
  List,
  ShowButton,
  TagField,
  useTable,
} from "@refinedev/antd";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Image,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { provider } from "../../App";
import { CalendarOutlined } from "@ant-design/icons";
import NumberRangeFilterDropdown from "../../components/pages/misc/NumberRangePicker";
import { Dayjs } from "dayjs";

// Define colors for charts
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#845EC2"];

// Mapping for subscription plan labels
const subscriptionPlanLabels: Record<string, string> = {
  NONE: "None",
  INITIAL_PURCHASE: "Initial Purchase",
  CANCELLATION: "Cancellation",
  BILLING_ISSUE: "Billing Issue",
  EXPIRATION: "Expiration",
  TRANSFER: "Transfer",
  PRODUCT_CHANGE: "Product Change",
  SUBSCRIPTION_EXTENDED: "Extended Subscription",
  SUBSCRIPTION_PAUSED: "Paused Subscription",
  NON_RENEWING_PURCHASE: "Non-renewing Purchase",
  UNCANCELLATION: "Un-cancellation",
  RENEWAL: "Renewal",
};

// Define types for aggregation data
type AggregationData = {
  type: string;
  value: number;
};

export const getGenderTag = (value: string) => {
  switch (value) {
    case "MALE":
      return <TagField value={value} color="green" />;
    case "FEMALE":
      return <TagField value={value} color="orange" />;
    default:
      return <TagField value={value} color="red" />;
  }
};

// Custom tick renderer for the x-axis to handle long labels and add padding
const CustomXAxisTick: React.FC<any> = ({ x, y, payload }) => {
  const words = payload.value.split(" ");
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={10} dy={16} textAnchor="middle" fill="#666" fontSize={12}>
        {words.map((word: string, index: number) => (
          <tspan key={index} x={0} dy={index === 0 ? 0 : 14}>
            {word}
          </tspan>
        ))}
      </text>
    </g>
  );
};

// Type guard function
const isLogicalFilter = (filter: CrudFilter): filter is LogicalFilter => {
  return "field" in filter && "operator" in filter;
};

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const { tableProps, setFilters, filters } = useTable({
    syncWithLocation: true,
  });

  // Define useState variables with types
  const [genderData, setGenderData] = useState<AggregationData[]>([]);
  const [subscriptionData, setSubscriptionData] = useState<AggregationData[]>(
    []
  );
  const [ageData, setAgeData] = useState<AggregationData[]>([]);
  // State to manage range filters
  const [rangeFilters, setRangeFilters] = useState<{
    [key: string]: [number | undefined, number | undefined];
  }>({});

  // Function to get current filter state
  const getRangeFilterState = (
    fieldName: string
  ): [number | undefined, number | undefined] => {
    return rangeFilters[fieldName] || [undefined, undefined];
  };

  // Function to set filter state
  const setRangeFilterState = (
    fieldName: string,
    values: [number | undefined, number | undefined]
  ) => {
    setRangeFilters((prev) => ({ ...prev, [fieldName]: values }));
    console.log("setRangeFilterState rangeFilter: ", rangeFilters);
  };

  // Function to apply range filters by setting separate min and max filters with operators
  const applyRangeFilter = (
    fieldName: string,
    min: number | undefined,
    max: number | undefined
  ) => {
    console.log(
      `Applying applyRangeFilter for ${fieldName}: min=${min}, max=${max}`
    );

    const newFilters: CrudFilters = filters.filter((f) => {
      return !(
        isLogicalFilter(f) &&
        f.field === fieldName &&
        (f.operator === "gte" || f.operator === "lte")
      );
    });

    if (min !== undefined) {
      newFilters.push({
        field: fieldName, // e.g., 'age'
        operator: "gte",
        value: min,
      });
    }
    if (max !== undefined) {
      newFilters.push({
        field: fieldName, // e.g., 'age'
        operator: "lte",
        value: max,
      });
    }

    console.log("New filters being applied:", newFilters);
    setFilters(newFilters);
  };

  useEffect(() => {
    const newRangeFilters: {
      [key: string]: [number | undefined, number | undefined];
    } = {};

    filters.filter(isLogicalFilter).forEach((f) => {
      if (f.operator === "gte" && f.field) {
        const fieldName = f.field.toLowerCase();
        newRangeFilters[fieldName] = [
          f.value as number,
          newRangeFilters[fieldName]?.[1],
        ];
      }
      if (f.operator === "lte" && f.field) {
        const fieldName = f.field.toLowerCase();
        newRangeFilters[fieldName] = [
          newRangeFilters[fieldName]?.[0],
          f.value as number,
        ];
      }
    });

    setRangeFilters(newRangeFilters);
  }, [filters]);

  useEffect(() => {
    // Fetch aggregation data from the backend
    provider
      .custom({
        url: `${apiUrl}/admin/user/aggregations`,
        method: "get",
      })
      .then((response) => {
        const { genderAggregation, subscriptionAggregation, ageDistribution } =
          response.data;

        // Format gender data for the Pie chart
        const formattedGenderData = genderAggregation.map((item: any) => ({
          type: item.gender,
          value: item._count.gender,
        }));
        setGenderData(formattedGenderData);

        // Format subscription data for the bar chart
        const formattedSubscriptionData = subscriptionAggregation.map(
          (item: any) => ({
            type:
              subscriptionPlanLabels[item.subscription_plan] ||
              item.subscription_plan,
            value: item._count.subscription_plan,
          })
        );
        setSubscriptionData(formattedSubscriptionData);

        // Format and sort age distribution data for the bar chart
        const formattedAgeData = Object.keys(ageDistribution)
          .filter((range) => range !== "NaN-NaN")
          .map((range) => ({
            type: range,
            value: ageDistribution[range],
          }))
          .sort(
            (a, b) =>
              parseInt(a.type.split("-")[0]) - parseInt(b.type.split("-")[0])
          );

        setAgeData(formattedAgeData);
      });
  }, [apiUrl]);

  const getFilterValue = (fieldName: string): any[] | null => {
    const values = filters
      .filter(isLogicalFilter)
      .filter((f) => f.field === fieldName)
      .map((f) => f.value)
      .flat();

    return values.length > 0 ? values : null;
  };

  return (
    <List>
      <Row gutter={[16, 16]}>
        {/* Gender Distribution Pie Chart */}
        <Col span={12}>
          <Card title="Gender Distribution">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={genderData}
                  dataKey="value"
                  nameKey="type"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  labelLine={false}
                  label={({ type, percent }) =>
                    `${type}: ${(percent * 100).toFixed(0)}%`
                  }
                >
                  {genderData.map((_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Card>
        </Col>

        {/* Subscription Plans Bar Chart */}
        <Col span={12}>
          <Card title="Subscription Plans">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={subscriptionData}
                margin={{ top: 30, right: 30, left: 20, bottom: 60 }}
              >
                <XAxis
                  dataKey="type"
                  tick={<CustomXAxisTick />}
                  interval={0}
                  padding="gap"
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#82ca9d">
                  <LabelList dataKey="value" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>

        {/* Age Distribution Bar Chart */}
        <Col span={24}>
          <Card title="Age Distribution">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={ageData}
                margin={{ top: 30, right: 30, left: 20, bottom: 40 }}
              >
                <XAxis dataKey="type" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8">
                  <LabelList dataKey="value" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>

      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="index"
          title="Sr. No."
          sorter={(a: any, b: any) => a.index - b.index}
        />
        <Table.Column dataIndex="name" title="Name" sorter={true} />
        <Table.Column
          dataIndex="email"
          title="Email"
          render={(value: any) => <TagField value={value} />}
          // Add filter settings if needed
        />
        <Table.Column
          dataIndex="photo_url"
          title="Photo Url"
          render={(photo_url) =>
            photo_url && (
              <Image
                src={photo_url}
                style={{ width: 50, height: 50, objectFit: "cover" }}
              />
            )
          }
        />
        <Table.Column
          dataIndex={["lifting_experience", "name"]}
          title="Lifting Experience"
          render={(value: string) => {
            switch (value) {
              case "BEGINNER":
                return <Tag color="green">{value}</Tag>;
              case "INTERMEDIATE":
                return <Tag color="orange">{value}</Tag>;
              case "ADVANCED":
                return <Tag color="red">{value}</Tag>;
              default:
                return <Tag>{value}</Tag>;
            }
          }}
          sorter={true}
          filters={[
            { text: "Beginner", value: "BEGINNER" },
            { text: "Intermediate", value: "INTERMEDIATE" },
            { text: "Advanced", value: "ADVANCED" },
          ]}
          filteredValue={getFilterValue("lifting_experience.name")}
          onFilter={(value, record: any) =>
            record.lifting_experience.name === value
          }
        />
        <Table.Column
          dataIndex="gender"
          title="Gender"
          render={getGenderTag}
          sorter={true}
          filters={[
            { text: "Male", value: "MALE" },
            { text: "Female", value: "FEMALE" },
            { text: "Not Specified", value: "NOT_SPECIFIED" },
          ]}
          filteredValue={getFilterValue("gender")}
          onFilter={(value, record: any) => {
            return record.gender === value;
          }}
        />
        <Table.Column
          dataIndex="height"
          title="Height"
          sorter={true}
          filterDropdown={(props) => (
            <NumberRangeFilterDropdown
              fieldName="height"
              filterState={getRangeFilterState("height")}
              setFilterState={(values) => setRangeFilterState("height", values)}
              confirm={props.confirm}
              clearFilters={props.clearFilters}
              applyRangeFilter={applyRangeFilter}
            />
          )}
        />
        <Table.Column
          dataIndex="current_weight"
          title="Current Weight"
          sorter={true}
          filterDropdown={(props) => (
            <NumberRangeFilterDropdown
              fieldName="current_weight"
              filterState={getRangeFilterState("current_weight")}
              setFilterState={(values) =>
                setRangeFilterState("current_weight", values)
              }
              confirm={props.confirm}
              clearFilters={props.clearFilters}
              applyRangeFilter={applyRangeFilter}
            />
          )}
        />
        <Table.Column
          dataIndex="goal_weight"
          title="Goal Weight"
          sorter={true}
          filterDropdown={(props) => (
            <NumberRangeFilterDropdown
              fieldName="current_weight"
              filterState={getRangeFilterState("current_weight")}
              setFilterState={(values) =>
                setRangeFilterState("current_weight", values)
              }
              confirm={props.confirm}
              clearFilters={props.clearFilters}
              applyRangeFilter={applyRangeFilter}
            />
          )}
        />
        <Table.Column
          dataIndex="subscription_plan"
          title="Subscription Plan"
          render={(value) => (
            <Tag>{subscriptionPlanLabels[value] || value}</Tag>
          )}
          filters={[
            { text: "Initial Purchase", value: "INITIAL_PURCHASE" },
            { text: "Cancellation", value: "CANCELLATION" },
            { text: "Billing Issue", value: "BILLING_ISSUE" },
            { text: "Expiration", value: "EXPIRATION" },
            { text: "Transfer", value: "TRANSFER" },
            { text: "Product Change", value: "PRODUCT_CHANGE" },
            { text: "Extended Subscription", value: "SUBSCRIPTION_EXTENDED" },
            { text: "Paused Subscription", value: "SUBSCRIPTION_PAUSED" },
            { text: "Non-renewing Purchase", value: "NON_RENEWING_PURCHASE" },
            { text: "Un-cancellation", value: "UNCANCELLATION" },
            { text: "Renewal", value: "RENEWAL" },
          ]}
          filteredValue={getFilterValue("subscription_plan")}
          onFilter={(value, record: any) => {
            return record.subscription_plan === value;
          }}
          sorter={true}
        />
        <Table.Column
          dataIndex="age"
          title="Age"
          sorter
          filterDropdown={(props) => (
            <NumberRangeFilterDropdown
              fieldName="age"
              filterState={getRangeFilterState("age")}
              setFilterState={(values) => setRangeFilterState("age", values)}
              confirm={props.confirm}
              clearFilters={props.clearFilters}
              applyRangeFilter={applyRangeFilter}
            />
          )}
        />
        <Table.Column
          dataIndex="id"
          title="Id"
          sorter={true}
          render={(value: any) => <TagField value={value} />}
        />
        <Table.Column
          dataIndex="date_joined"
          title="Date Joined"
          sorter
          // filteredValue={
          //   filters.some(
          //     (f) =>
          //       isLogicalFilter(f) &&
          //       f.field === "date_joined" &&
          //       f.operator === "between"
          //   )
          //     ? [true]
          //     : null
          // }
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8 }}>
              <DatePicker.RangePicker
                onChange={(dates, dateStrings) => {
                  setSelectedKeys(dates ? [dateStrings.join(",")] : []);
                }}
                onOk={() => confirm()}
              />
              <Button
                type="primary"
                onClick={() => confirm()}
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                Filter
              </Button>
              <Button
                onClick={() => setSelectedKeys([])}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </div>
          )}
          filterIcon={(filtered) => (
            <CalendarOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          )}
          // onFilter={(value: any, record) => {
          //   if (!value || value.length === 0) {
          //     return true;
          //   }
          //   const [startDateStr, endDateStr] = value[0].split(",");
          //   const startDate = moment(startDateStr);
          //   const endDate = moment(endDateStr);
          //   const createdAt = moment(record.createdAt);
          //
          //   const isAfterStartDate =
          //     !startDate.isValid() || createdAt.isSameOrAfter(startDate, "day");
          //   const isBeforeEndDate =
          //     !endDate.isValid() || createdAt.isSameOrBefore(endDate, "day");
          //
          //   return isAfterStartDate && isBeforeEndDate;
          // }}
          render={(value: Dayjs | null) => (
            <DateField value={value} format={"MM-DD-YYYY"} />
          )}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
