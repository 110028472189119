import React from "react";
import { IResourceComponentsProps, useShow, useOne } from "@refinedev/core";
import {
  Show,
  NumberField,
  TagField,
  TextField,
  EmailField,
  DateField,
} from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: blockData, isLoading: blockIsLoading } = useOne({
    resource: "block",
    id: record?.blockId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ""} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Email</Title>
      <EmailField value={record?.email} />
      <Title level={5}>Photo Url</Title>
      <TextField value={record?.photo_url} />
      <Title level={5}>Gender</Title>
      <TextField value={record?.gender} />
      <Title level={5}>Height</Title>
      <NumberField value={record?.height ?? ""} />
      <Title level={5}>Current Weight</Title>
      <NumberField value={record?.current_weight ?? ""} />
      <Title level={5}>Goal Weight</Title>
      <NumberField value={record?.goal_weight ?? ""} />
      <Title level={5}>Date Of Birth</Title>
      <DateField value={record?.date_of_birth} />
      <Title level={5}>Physical Activity</Title>
      <TextField value={record?.physical_activity} />
      <Title level={5}>Goal</Title>
      <TextField value={record?.goal} />
      <Title level={5}>Subscription Plan</Title>
      <TextField value={record?.subscription_plan} />
      <Title level={5}>Block</Title>
      {blockIsLoading ? <>Loading...</> : <>{blockData?.data?.name}</>}
    </Show>
  );
};
