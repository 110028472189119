import { Refine } from "@refinedev/core";
import { notificationProvider, RefineThemes } from "@refinedev/antd";
import routerBindings, {
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { dataProvider } from "rest-data-provider";
import { BrowserRouter } from "react-router-dom";

import { ConfigProvider } from "antd";
import "@refinedev/antd/dist/reset.css";
import { getRoutes } from "./utils/routes.utils";
import {
  AlibabaOutlined,
  AppleOutlined,
  AppstoreAddOutlined,
  AppstoreOutlined,
  AuditOutlined,
  BlockOutlined,
  BulbOutlined,
  CoffeeOutlined,
  DiffOutlined,
  DingtalkOutlined,
  ExpandAltOutlined,
  FileImageOutlined,
  FileOutlined,
  GroupOutlined,
  HighlightOutlined,
  InfoCircleOutlined,
  LikeOutlined,
  PicLeftOutlined,
  PieChartOutlined,
  RadarChartOutlined,
  RadiusSettingOutlined,
  SlidersOutlined,
  SnippetsOutlined,
  SplitCellsOutlined,
  ToolOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import authProvider from "./authProvider";
import { ColorModeContextProvider } from "./contexts/color-mode";

export const provider = dataProvider(process.env.REACT_APP_API_URL!);

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ColorModeContextProvider>
        <ConfigProvider theme={RefineThemes.Blue}>
          <Refine
            routerProvider={routerBindings}
            authProvider={authProvider}
            dataProvider={provider}
            notificationProvider={notificationProvider}
            resources={[
              {
                name: "admin/user",
                list: "/admin/user",
                show: "/admin/user/show/:id",
                meta: { label: "Users", icon: <UsergroupAddOutlined /> },
              },
              {
                name: "admin/affiliate",
                list: "/admin/affiliate",
                show: "/admin/affiliate/show/:id",
                edit: "/admin/affiliate/edit/:id",
                create: "/admin/affiliate/create",
                meta: { label: "Affiliates", icon: <AuditOutlined /> },
              },
              {
                name: "attachment",
                list: "/attachment",
                show: "/attachment/show/:id",
                create: "/attachment/create",
                edit: "/attachment/edit/:id",
                icon: <FileOutlined />,
              },
              {
                name: "block",
                list: "/block",
                show: "/block/show/:id",
                create: "/block/create",
                edit: "/block/edit/:id",
                icon: <BlockOutlined />,
              },
              {
                name: "split",
                meta: {
                  label: "Dynamic Block",
                },
              },
              {
                name: "blockTemplate",
                list: "/blockTemplate",
                show: "/blockTemplate/show/:id",
                create: "/blockTemplate/create",
                edit: "/blockTemplate/edit/:id",
                meta: {
                  label: "Splits",
                  icon: <DiffOutlined />,
                  parent: "split",
                },
              },
              {
                name: "groupedSplitDay",
                list: "/groupedSplitDay",
                show: "/groupedSplitDay/show/:id",
                create: "/groupedSplitDay/create",
                edit: "/groupedSplitDay/edit/:id",
                meta: {
                  label: "Grouped Days",
                  icon: <PicLeftOutlined />,
                  parent: "split",
                },
              },
              {
                name: "splitDay",
                list: "/splitDay",
                show: "/splitDay/show/:id",
                create: "/splitDay/create",
                edit: "/splitDay/edit/:id",
                meta: {
                  label: "Split Day",
                  icon: <PicLeftOutlined />,
                  parent: "split",
                },
              },
              {
                name: "equipment",
                list: "/equipment",
                show: "/equipment/show/:id",
                create: "/equipment/create",
                edit: "/equipment/edit/:id",
                icon: <SplitCellsOutlined />,
              },
              {
                name: "exercise",
                list: "/exercise",
                show: "/exercise/show/:id",
                create: "/exercise/create",
                edit: "/exercise/edit/:id",
                icon: <DingtalkOutlined />,
              },
              {
                name: "dummy",
                list: "/dummy",
                icon: <SnippetsOutlined />,
              },
              {
                name: "liftingExperience",
                list: "/liftingExperience",
                show: "/liftingExperience/show/:id",
                create: "/liftingExperience/create",
                edit: "/liftingExperience/edit/:id",
                icon: <RadarChartOutlined />,
              },
              {
                name: "exerciseType",
                list: "/exerciseType",
                show: "/exerciseType/show/:id",
                create: "/exerciseType/create",
                edit: "/exerciseType/edit/:id",
                icon: <SlidersOutlined />,
              },
              {
                name: "food",
                list: "/food",
                show: "/food/show/:id",
                create: "/food/create",
                edit: "/food/edit/:id",
                icon: <AppleOutlined />,
              },
              {
                name: "grip",
                list: "/grip",
                show: "/grip/show/:id",
                create: "/grip/create",
                edit: "/grip/edit/:id",
                icon: <ToolOutlined />,
              },
              {
                name: "gripWidth",
                list: "/gripWidth",
                show: "/gripWidth/show/:id",
                create: "/gripWidth/create",
                edit: "/gripWidth/edit/:id",
                icon: <ExpandAltOutlined />,
              },
              {
                name: "hand",
                list: "/hand",
                show: "/hand/show/:id",
                create: "/hand/create",
                edit: "/hand/edit/:id",
                icon: <LikeOutlined />,
              },
              {
                name: "mainMuscle",
                list: "/mainMuscle",
                show: "/mainMuscle/show/:id",
                create: "/mainMuscle/create",
                edit: "/mainMuscle/edit/:id",
                icon: <AppstoreAddOutlined />,
              },
              {
                name: "meal",
                list: "/meal",
                show: "/meal/show/:id",
                create: "/meal/create",
                edit: "/meal/edit/:id",
                icon: <PieChartOutlined />,
              },
              {
                name: "mealImage",
                list: "/mealImage",
                show: "/mealImage/show/:id",
                create: "/mealImage/create",
                edit: "/mealImage/edit/:id",
                icon: <FileImageOutlined />,
              },
              {
                name: "mealInstruction",
                list: "/mealInstruction",
                show: "/mealInstruction/show/:id",
                create: "/mealInstruction/create",
                edit: "/mealInstruction/edit/:id",
                icon: <HighlightOutlined />,
              },
              {
                name: "muscleGroup",
                list: "/muscleGroup",
                show: "/muscleGroup/show/:id",
                create: "/muscleGroup/create",
                edit: "/muscleGroup/edit/:id",
                icon: <AlibabaOutlined />,
              },
              {
                name: "primaryMuscle",
                list: "/primaryMuscle",
                show: "/primaryMuscle/show/:id",
                create: "/primaryMuscle/create",
                edit: "/primaryMuscle/edit/:id",
                icon: <AppstoreOutlined />,
              },
              {
                name: "program",
                list: "/program",
                show: "/program/show/:id",
                create: "/program/create",
                edit: "/program/edit/:id",
                icon: <GroupOutlined />,
              },
              {
                name: "recipe",
                list: "/recipe",
                show: "/recipe/show/:id",
                create: "/recipe/create",
                edit: "/recipe/edit/:id",
                icon: <CoffeeOutlined />,
              },
              // {
              //   name: "recipeIngredient",
              //   list: "/recipeIngredient",
              //   show: "/recipeIngredient/show/:id",
              //   create: "/recipeIngredient/create",
              //   edit: "/recipeIngredient/edit/:id",
              //   icon: <KeyOutlined />,
              // },
              {
                name: "tips",
                list: "/tips",
                show: "/tips/show/:id",
                create: "/tips/create",
                edit: "/tips/edit/:id",
                icon: <BulbOutlined />,
              },
              {
                name: "appVersion",
                list: "/appVersion",
                show: "/appVersion/show/:id",
                create: "/appVersion/create",
                edit: "/appVersion/edit/:id",
                icon: <InfoCircleOutlined />,
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            {getRoutes()}
            <UnsavedChangesNotifier />
            <DocumentTitleHandler />
          </Refine>
        </ConfigProvider>
      </ColorModeContextProvider>
    </BrowserRouter>
  );
};

export default App;
