// NumberRangeFilterDropdown.tsx
import React, { useState, useEffect } from "react";
import { Slider, InputNumber, Button, Space } from "antd";

interface NumberRangeFilterDropdownProps {
  fieldName: string;
  filterState: [number | undefined, number | undefined];
  setFilterState: (values: [number | undefined, number | undefined]) => void;
  confirm: () => void;
  clearFilters?: () => void;
  applyRangeFilter: (
    fieldName: string,
    min: number | undefined,
    max: number | undefined
  ) => void;
}

const NumberRangeFilterDropdown: React.FC<NumberRangeFilterDropdownProps> = ({
  fieldName,
  filterState,
  setFilterState,
  confirm,
  clearFilters,
  applyRangeFilter,
}) => {
  const [min, setMin] = useState<number | undefined>(filterState[0]);
  const [max, setMax] = useState<number | undefined>(filterState[1]);

  // Define slider range based on fieldName or pass as props for dynamic ranges
  const getSliderRange = (): [number, number] => {
    switch (fieldName) {
      case "age":
        return [0, 100]; // Example range for age
      case "height":
        return [50, 250]; // Example range for height in cm
      case "current_weight":
      case "goal_weight":
        return [0, 200]; // Example range for weight in kg
      default:
        return [0, 100]; // Default range
    }
  };

  const [sliderMin, sliderMax] = getSliderRange();

  // Synchronize slider and input numbers when filterState changes
  useEffect(() => {
    setMin(filterState[0]);
    setMax(filterState[1]);
  }, [filterState]);

  const onSliderChange = (value: number[]) => {
    setMin(value[0]);
    setMax(value[1]);
  };

  const onMinChange = (value: number | null) => {
    setMin(value ?? undefined);
  };

  const onMaxChange = (value: number | null) => {
    setMax(value ?? undefined);
  };

  const onApply = () => {
    // Debugging: Log the min and max values
    console.log(`Applying filter for ${fieldName}: min=${min}, max=${max}`);
    setFilterState([min, max]);
    applyRangeFilter(fieldName, min, max);
    confirm();
  };

  const onReset = () => {
    // Debugging: Log the reset action
    console.log(`Resetting filter for ${fieldName}`);
    setMin(undefined);
    setMax(undefined);
    setFilterState([undefined, undefined]);
    clearFilters?.();
    applyRangeFilter(fieldName, undefined, undefined);
    confirm();
  };

  return (
    <div style={{ padding: 8, width: 300 }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        {/* Slider Range */}
        <Slider
          range
          min={sliderMin}
          max={sliderMax}
          value={[
            min !== undefined ? min : sliderMin,
            max !== undefined ? max : sliderMax,
          ]}
          onChange={onSliderChange}
          style={{ width: "100%" }}
        />
        {/* Input Numbers for Min and Max */}
        <Space>
          <InputNumber
            min={sliderMin}
            max={sliderMax}
            value={min}
            onChange={onMinChange}
            placeholder="Min"
            style={{ width: "100%" }}
          />
          <InputNumber
            min={sliderMin}
            max={sliderMax}
            value={max}
            onChange={onMaxChange}
            placeholder="Max"
            style={{ width: "100%" }}
          />
        </Space>
        {/* Filter and Reset Buttons */}
        <Space style={{ marginTop: 8 }}>
          <Button
            type="primary"
            onClick={onApply}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button onClick={onReset} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </Space>
    </div>
  );
};

export default NumberRangeFilterDropdown;
